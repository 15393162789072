import React from "react";
import resolveLink from "~utils/resolveLink";
import {Link} from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import ImageModule from "../components/modules/ImageModule";
import DoubleImageModule from "../components/modules/DoubleImageModule";
import VideoModule from "../components/modules/VideoModule";
import QuoteModule from "../components/modules/QuoteModule";
import Button from "../components/blocks/Button";
import Download from "~components/blocks/Download";

const {mobile, tablet} = breakpoints;

const serializers = {
  types: {
    block: (props) => {
      if (props?.node?.style === "h1") {
        return <h1 css={css``}>{props.children}</h1>;
      }
      if (props?.node?.style === "h2") {
        return (
          <h2
            css={css`
              font-size: 2.5rem;
              line-height: 1.2;
              ${tablet} {
                font-size: 1.5rem;
              }
            `}
          >
            {props.children}
          </h2>
        );
      }
      if (props?.node?.style === "h3") {
        return (
          <h3
            css={css`
              font-size: 2rem;
              line-height: 1.2;
              ${tablet} {
                font-size: 1.2rem;
              }
            `}
          >
            {props.children}
          </h3>
        );
      }
      if (props?.node?.style === "h4") {
        return (
          <h4
            css={css`
              font-size: 1.6rem;
              line-height: 1.2;
              ${tablet} {
                font-size: 0.9rem;
              }
            `}
          >
            {props.children}
          </h4>
        );
      }
      if (props.children[0] === "") {
        return <br />;
      }
      return BlockContent.defaultSerializers.types.block(props);
    },
    imageModule: ({node}) => {
      return (
        <ImageModule
          image={node?.image?.asset?.id}
          caption={node?.caption}
          aspectRatio={node?.image?.asset?.metadata.dimensions.aspectRatio}
          css={css`
            margin: 78px 0;
            ${mobile} {
              margin: 72px 0;
            }
          `}
        />
      );
    },
    doubleImageModule: ({node}) => {
      return (
        <DoubleImageModule
          imageLeft={node?.imageLeft?.asset?.id}
          imageRight={node?.imageRight?.asset?.id}
          captionLeft={node?.captionLeft}
          captionRight={node?.captionRight}
          css={css`
            margin-top: 122px;
            margin-bottom: 122px;
          `}
        />
      );
    },
    videoModule: ({node}) => {
      return (
        <VideoModule
          video={node?.url}
          caption={node?.caption}
          placeholder={node?.placeholderImage?.asset?.id}
          css={css`
            margin: 80px 0;
            ${mobile} {
              margin: 74px 0;
            }
          `}
        />
      );
    },
    quoteModule: ({node}) => {
      return (
        <QuoteModule
          className="article-centered"
          quote={node?.quote}
          attribution={node?.attribution}
          css={css`
            margin: 80px 0;
          `}
        />
      );
    },
    fileLink: ({node}) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            margin: 38px 0;
            ${mobile} {
              column-gap: 10px;
            }
          `}
        >
          <div
            css={css`
              grid-column: 4/10;
              width: 100%;
              max-width: 750px;
              margin-left: auto;
              margin-right: auto;
              ${mobile} {
                grid-column: span 12;
              }
            `}
          >
            <Download file={node?.file} key={node?.fileName} name={node?.fileName} />
          </div>
        </div>
      );
    },
    internalLink: ({node}) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            margin: 38px 0;
            ${mobile} {
              column-gap: 10px;
            }
          `}
        >
          <div
            css={css`
              grid-column: 4/10;
              width: 100%;
              max-width: 750px;
              margin-left: auto;
              margin-right: auto;
              ${mobile} {
                grid-column: span 12;
              }
            `}
          >
            <Button to={resolveLink(node?.to?._type, node?.to?.slug?.current)}>
              {node.linkText}
            </Button>
          </div>
        </div>
      );
    },
    externalLink: ({node}) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 20px;
            margin: 38px 0;
            ${mobile} {
              column-gap: 10px;
            }
          `}
        >
          <div
            css={css`
              grid-column: 4/10;
              width: 100%;
              max-width: 750px;
              margin-left: auto;
              margin-right: auto;
              ${mobile} {
                grid-column: span 12;
              }
            `}
          >
            <Button
              external
              className={`h6`}
              to={node.url}
              css={css`
                display: grid;
                align-items: center;
                grid-template-columns: max-content;
              `}
            >
              {node.linkText}
            </Button>
          </div>
        </div>
      );
    },
  },
  marks: {
    internalLink: (props) => {
      return (
        <Link to={resolveLink(props?.mark?.page?._type, props?.mark?.page?.slug?.current)}>
          <span
            css={css`
              border-bottom: 1px solid var(--black);
              padding-bottom: 0px;
            `}
          >
            {props.children}
          </span>
        </Link>
      );
    },
    link: (props) => {
      return (
        <a
          id={props.mark.page?._ref}
          href={props.mark?.href}
          target={props.mark.blank ? `_blank` : `_self`}
          css={css`
            border-bottom: 1px solid var(--black);
            padding-bottom: 0px;
          `}
        >
          {props.children}
        </a>
      );
    },
  },
};

const RichText = ({content}) =>
  content ? <BlockContent blocks={content} serializers={serializers} /> : null;

export default RichText;
